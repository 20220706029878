import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";

export type SchoolAssociation = {
    instructor_id: string
    student_id: string
    instructor_name: string
    student_name: string
    school_id: string
    school_short_name: string
    start_date: string
    end_date: string
    district_id: string
    district_name: string
}

export type SchoolAssociationOutput = {
    students: InstructorStudentMap;
}

export type InstructorStudentMap = Map<string, Map<string, SchoolAssociation>>;
export type StudentInstructorMap = Map<string, Set<string>>;

const QUERY_KEY = ["school_associations"];

const convertToOutput = (school_id: string, associations: SchoolAssociation[]): SchoolAssociationOutput => {
    const students = new Map<string, Map<string, SchoolAssociation>>();
    associations.forEach(association => {

        if (association.school_id !== school_id) {
            return;
        }

        if (!students.has(association.instructor_id)) {
            students.set(association.instructor_id, new Map());
        }


        students.get(association.instructor_id)?.set(association.student_id, association);
    });

    return { students };
}

const schoolAssociations = async (school_id: string): Promise<SchoolAssociationOutput> => {
    const url = `/schools/${school_id}/associations`;
    const { data } = await authorizedBackendGet<{ associations: SchoolAssociation[] }>(url);
    const results = convertToOutput(school_id, data.associations);
    return results;
};

export const useSchoolAssociations = (school_id: string) => {
    return useQuery<SchoolAssociationOutput, Error>({
        queryKey: QUERY_KEY.concat(school_id),
        queryFn: () => schoolAssociations(school_id),
        staleTime: 1000 * 60 * 60, // 1 hour,
        retry: false
    });
};
