import { authProvider } from "../auth";
import { useEffect, useState } from "react";
import { Icons } from "@/components/icons";
import { authorizedBackendPost } from "@/lib/backend";

function StudentData() {
  const [jwt, setJwt] = useState<string | null>(null);

  useEffect(() => {
    async function fetchExploJwt() {
      const body = {
        "provided_id": authProvider.email,
        "embed_id": authProvider.studentDataEmbedId || "ovARNGWAlV" // Data Portal v3. We do this just in case it's not set correctly
      }

      const results = await authorizedBackendPost("/api/generate_explo_jwt",
        body
      )
      setJwt(results.data.jwt)
    }
    fetchExploJwt()
  }, [])

  if (!jwt) {
    return <Icons.spinner className="m-auto h-4 w-4 animate-spin" />
  }


  return (
    <div className="w-full">
      <explo-dashboard
        dash-jwt={jwt}
        isProduction={true}
        environment="production"
        refresh-minutes={10}
        style={{ height: "100vh", width: "100vw" }}
      >
      </explo-dashboard>
    </div >
  )
}

export default StudentData;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': any;
    }
  }
}
