import { useState } from "react";
import SchoolSelection from "./SchoolSelection";
import DateRange from "./DateRange";
import LogTable from "./LogTable";
import DateNavigator from "./DateNavigator";
import { Day } from "@/lib/core/day";
import { useSchoolAssociations } from "@/hooks/useSchoolAssociations";
import { useInstructionalLog } from "@/hooks/useInstructionalLog";
import Loading from "../ui/loading";
import { useMaxCurriculum } from "@/hooks/useMaxCurriculum";
import { useSchoolsList } from "@/hooks/useSchoolsList";

// TODO: Decompose into objects rather than everything at the top level
type Props = {
    startDate: Date;
    school: string; // TODO
    // Instructor/Student pairs
    holidays: Day[];
    setDate: (date: Date) => void;
}

type LoadableLogProps = {
    selectedSchool: string;
    startDate: Date;
    endDate: Date;
    holidays: Day[];
}


const LoadableLog: React.FC<LoadableLogProps> = ({
    startDate,
    endDate,
    selectedSchool,
    holidays,
}) => {
    const associations = useSchoolAssociations(selectedSchool);
    const instructionalLog = useInstructionalLog(selectedSchool, startDate);
    const latestCurriculumByStudent = useMaxCurriculum(selectedSchool);

    if (!associations.data) {
        return <div><Loading {...associations} /></div>
    }

    if (!instructionalLog.data) {
        return <Loading {...instructionalLog} />
    }

    if (!latestCurriculumByStudent.data) {
        return <Loading {...latestCurriculumByStudent} />
    }

    return (<LogTable
        upsert={instructionalLog.upsert}
        latestCurriculumByStudent={latestCurriculumByStudent.data ?? new Map()}
        startDate={Day.fromDate(startDate)}
        endDate={Day.fromDate(endDate)}
        holidays={holidays}
        students={associations.data}
        writeable={instructionalLog.data.writeable ?? false}
        log={instructionalLog.data.entries}
        setLog={(_prev: any) => { }} />)
}

const Log: React.FC<Props> = ({ startDate, school, holidays, setDate }) => {
    const [selectedSchool, setSelectedSchool] = useState(school);
    const schools = useSchoolsList();

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 13);

    const prevWeek = () => {
        const d = new Date(startDate);
        d.setDate(d.getDate() - 14);
        setDate(d);
    }
    const nextWeek = () => {
        const d = new Date(startDate);
        d.setDate(d.getDate() + 14);
        setDate(d);
    }

    return (
        <div className="flex flex-col gap-6">
            <div className="max-w-fit"><SchoolSelection schools={schools?.data} selectedSchool={selectedSchool} setSelectedSchool={setSelectedSchool} /></div>
            <DateRange startDate={startDate} setDate={setDate} />
            <DateNavigator prevWeek={prevWeek} nextWeek={nextWeek} legend={"s = student absence, i = instructor absence, o = other responsibility"} />
            <LoadableLog
                selectedSchool={selectedSchool}
                startDate={startDate}
                endDate={endDate}
                holidays={holidays} />
        </div>
    )
}

export default Log;