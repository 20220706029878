import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";

export type SchoolEntry = {
    school_id: string
    school_short_name: string
    start_date: string
    end_date: string
    district_id: string
    district_name: string
}

export type SchoolListOutput = {
    schools: Map<string, SchoolEntry>;
}

const QUERY_KEY = ["schools_list"];

const convertToOutput = (associations: SchoolEntry[]): SchoolListOutput => {
    const schools = new Map<string, SchoolEntry>();
    associations.forEach(association => {
        schools.set(association.school_id, association);
    });
    return { schools };
}

const schoolsList = async (): Promise<SchoolListOutput> => {
    const url = `/schools/associations`;
    const { data } = await authorizedBackendGet<{ associations: SchoolEntry[] }>(url);

    const results = convertToOutput(data.associations)
    return results;
};

export const useSchoolsList = () => {
    return useQuery<SchoolListOutput, Error>({
        queryKey: QUERY_KEY,
        queryFn: () => schoolsList(),
        staleTime: 1000 * 60 * 60, // 1 hour,
        retry: false
    });
};
