import { authorizedBackendPost, unauthorizedGet } from "@/lib/backend";

export async function searchForEmail(email: string) {
    return await unauthorizedGet<{ factor: string, contact_support?: boolean }>(`/auth/factor`, {
        email
    });
}

export async function sendMagicLink(email: string) {
    return await authorizedBackendPost("/auth/stytch/associate/magic_link", {
        email
    });
}