import { CellContext, Column, ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@/components/DataTable";
import { Button } from "@/components/ui/button";
import { ArrowUpDown, MonitorPlay } from "lucide-react";
import {
  Recording as WireRecording,
  useRecordings,
} from "@/hooks/useRecordings";
import { Icons } from "@/components/icons";

// We need to convert some columns from what's sent over the wire from the server.
// For example, timestamp is converted from an iso8601 string to a Date object.
type Recording = WireRecording & {
  timestamp: Date;
};

function transformWireIntoRecording(recording: WireRecording): Recording {
  let timeStr = recording.timestamp;
  if (!timeStr) {
    return recording as Recording;
  }

  // For some reason, the iso8601 strings from the backend do not have the timezone modifier on the end
  // We know these are UTC times, so we are manually adding the Z to indicate UTC
  if (!timeStr.endsWith("Z")) {
    timeStr = timeStr + "Z";
  }

  const utcDate = new Date(timeStr);

  return {
    ...recording,
    timestamp: utcDate,
  } as Recording;
}

const columnIds = [
  "student_name",
  "timestamp",
  "cycle_number",
  "instructor_name",
  "school_name",
  "district_name",
];

const colIdToLabelMap: { [colname: string]: string } = {
  student_name: "Student",
  timestamp: "Date",
  cycle_number: "Cycle",
  instructor_name: "Instructor",
  school_name: "School",
  district_name: "District",
};

const columnDef: ColumnDef<Recording>[] = [
  {
    accessorKey: "recording_url",
    header: "Link to recording",
    cell: (row) => {
      return <a href={row.getValue() as string} target="blank" ><MonitorPlay className="ml-auto mr-auto" /></a>
    }
  },
  ...columnIds.map((col) => {
    if (col === "timestamp") {
      return {
        accessorKey: col,
        header: ({ column }: { column: Column<Recording, unknown> }) => (
          <SortableColumnHeader column={column} />
        ),
        // Render the timestamp as a date in the local timezone
        cell: (row: CellContext<Recording, unknown>) => {
          const utcDate = row.getValue() as Date | undefined;
          const localDateStr = utcDate?.toLocaleDateString();
          return localDateStr;
        },
        // To filter, do a string match, but always fail if there is no date.
        filterFn: (row, columnId, filterValue: string) => {
          const utcDate = row.getValue(columnId) as Date | undefined;
          const localDateStr = utcDate?.toLocaleDateString();
          return localDateStr ? localDateStr.includes(filterValue) : false;
        },
      } as ColumnDef<Recording, unknown>;
    }
    return {
      accessorKey: col,
      header: ({ column }: { column: Column<Recording, unknown> }) => (
        <SortableColumnHeader column={column} />
      ),
    };
  }),
];

const filters = columnIds.map((id) => {
  return {
    id,
    label: colIdToLabel(id)
  }
})

export default function MyRecordings() {
  const { data, isLoading } = useRecordings();
  const finalData = (data || []).map<Recording>(transformWireIntoRecording);

  if (isLoading || !data) {
    return <div className="m-auto"><Icons.spinner className="animate-spin ml-auto mr-auto mt-48" /></div>
  }

  return (
    <div className="p-4 w-full">
      <DataTable
        columns={columnDef}
        data={finalData.filter((a) => !!a.district_name)}
        filters={filters}
      />
    </div>
  )
}

export function SortableColumnHeader({ column }: {
  column: Column<Recording, unknown>
}) {
  return <Button
    variant="ghost"
    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
  >
    {colIdToLabel(column.id)}
    <ArrowUpDown className="ml-2 h-4 w-4" />
  </Button>
}

function colIdToLabel(id: string) {
  const mapped = colIdToLabelMap[id];
  if (mapped) return mapped;

  return id.split("_").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ")
}
