import { useEffect } from "react";
import { useNavigate } from "react-router";
import { LocalStorageParams, SearchParams } from "@/main";
import { createSearchParams } from "react-router-dom";
import LoginFlow from "./LoginFlow";

function LoginNew() {
  const searchParams = new URLSearchParams(window.location.search);

  // const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const tokenFromUrl = searchParams.get(SearchParams.JWT);

    // If we have a token from the url then set it to local storage. This is 
    // used by authentication to check if the user is already logged in.
    if (tokenFromUrl !== null) {
      localStorage.setItem(LocalStorageParams.JWT, tokenFromUrl);

      // Redirect to the home page with the student id and cycle from local
      // storage. We set these to local storage in the handleClick (login) function. 
      const cycle = localStorage.getItem(LocalStorageParams.Cycle);
      const studentId = localStorage.getItem(LocalStorageParams.StudentId);

      return navigate({
        pathname: "/",
        search: createSearchParams({
          [SearchParams.Cycle]: cycle ?? "",
          [SearchParams.StudentId]: studentId ?? ""
        }).toString()
      });
    }
  }, [searchParams])

  return (
    <>
      <div className="container relative h-screen items-center justify-center py-4 lg:py-0 md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="mx-auto flex w-full flex-col items-center justify-center space-y-6 sm:w-[350px]">
          <div className="relative">
            <img id="logo" src="/Logo-Blue.svg" alt="Once" style={{ width: "250px" }} />
          </div>
          <LoginFlow />
          <div className="underline flex flex-row gap-8 text-sm">
            <a href="https://www.tryonce.com/terms" target="_blank">Terms of Service</a>
            <a href="https://www.tryonce.com/privacy" target="_blank">Privacy Policy</a>
          </div>
        </div>
        <div className="relative h-full flex-col place-items-center justify-center flex pt-6 lg:pt-0">
          <div className="h-full w-full bg-contain lg:bg-auto bg-right-top lg:bg-right bg-no-repeat" style={{
            backgroundImage: "url(/wallpaper.jpg)",
          }} />

        </div>
      </div>
    </>
  );
}

export default LoginNew;