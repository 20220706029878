import { authProvider } from "@/auth";
import Log from "@/components/InstructionalLog";
import { nextWeekday } from "@/components/InstructionalLog/utils";
import { useDistrictCalendar } from "@/hooks/useDistrictCalendar";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useState } from "react";

function InstructionalLog() {
  const { instructionalLog } = useFlags();
  const [selectedSchool, _setSelectedSchool] = useState("622d7e69");
  const [startDate, setStartDate] = useState(nextWeekday(new Date()));
  const noninstructionalDates = useDistrictCalendar(selectedSchool, startDate);
  const { data: holidays } = noninstructionalDates
  const setDate = useCallback((date: Date) => {
    const adjustedDate = nextWeekday(date);
    setStartDate(adjustedDate);
  }, []);

  if (!authProvider.instructionalLogUrl) {
    return (
      <div className="flex w-full justify-center p-20">
        <p>
          <strong>Oops!</strong> There is no instructional log associated with
          this user. If you believe this is an error, please email Once support
          at support@tryonce.com.
        </p>
      </div>
    );
  }

  if (!instructionalLog) {
    return (
      <div className="flex w-full justify-center p-20">
        <p>
          <a
            href={authProvider.instructionalLogUrl}
            target="_blank"
            className="text-blue-700 underline text-lg"
          >
            Click here to open your Instructional Log.
          </a>
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full p-3 gap-6">
      <Log startDate={startDate} school={selectedSchool} holidays={holidays?.map(
        (noninstructionalDate) => noninstructionalDate.date
      ) ?? []} setDate={setDate} />
    </div>
  )
}

export default InstructionalLog;
