import { createRef, FC, useState } from "react"
import { LoginStep } from "./type"
import { Input } from "../ui/input"
import { searchForEmail } from "./util"
import { Button } from "../ui/button"
import { ContactSupportMessage } from "."

type Props = {
    nextStep: (step: LoginStep, associated_data?: any) => void
}

const AskForEmailStep: FC<Props> = (props: Props) => {
    const inputRef = createRef<HTMLInputElement>();
    const [processing, setProcessing] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const { nextStep } = props
    const clearProcessingNextStep = (step: LoginStep, associated_data?: any) => {
        setProcessing(false);
        nextStep(step, associated_data);
    };
    const handleSubmit = async () => {
        setProcessing(true);
        const email = inputRef.current?.value;
        const response = await searchForEmail(email!);
        if (response.status === 200) {
            if (response.data.contact_support) {
                clearProcessingNextStep(LoginStep.ContactSupport, (<ContactSupportMessage message="Your account is not set up yet" />))
            } else {
                clearProcessingNextStep(LoginStep.ProcessFactor, { factor: response.data.factor, email })
            }
        } else {
            clearProcessingNextStep(LoginStep.ContactSupport, (<ContactSupportMessage message={response.statusText} />))
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.currentTarget.value)
        setCanSubmit(e.currentTarget.value.length > 0)
    }

    const onEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault()
            if (canSubmit) {
                handleSubmit()
            }
        }
    }

    return (<div className="py-8 flex flex-col gap-4">
        <p>To Login, please enter your email address:</p>
        <Input ref={inputRef} type="email" disabled={processing} placeholder="email address" onChange={handleInputChange} onKeyDown={onEnter} value={inputValue} />
        <Button variant="once" disabled={processing || !canSubmit} onClick={handleSubmit}>Continue</Button>
    </div>
    )
}

export default AskForEmailStep