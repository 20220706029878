import { ReactNode } from "react";
import { dayOfWeek, formatDateShort } from "./utils";
import { Day } from "@/lib/core/day";

type Props = {
    dates: Day[],
}

const dateNode = (day: Day): ReactNode => {
    const dow = dayOfWeek(day);
    const short = formatDateShort(day);

    return <>{dow}<br />{short}</>
}

const HeaderRow: React.FC<Props> = ({ dates }) => {
    return (
        <tr>
            <td className={`p-1.5 LogCellSmaller overflow-hidden`} colSpan={5}></td>
            {dates.map(date => {
                const ds = date.toString();
                return <td className={`LogCellSmaller`} key={ds}>
                    {dateNode(date)}
                </td>
            })}
        </tr>);
};

export default HeaderRow;