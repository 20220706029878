import { authProvider } from "@/auth";
import { authorizedBackendGet, authorizedBackendPost } from "@/lib/backend";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";

export type BasicUserListEntry = {
    user_id: string;
    name: string;
    email: string;
};

export type UserImpersonationContextType = {
    startImpersonating: (user: BasicUserListEntry) => void;
    stopImpersonating: () => void;
    impersonationHash: string | null;
    impersonatedUser: BasicUserListEntry | null;
}
const UserImpersonationContext = createContext<UserImpersonationContextType | null>(null);
export const UserImpersonationProvider = UserImpersonationContext.Provider;

const QUERY_KEY = ["ImpersonationUserList"];
const STORAGE_KEY = "impersonation";

const fetchUserList = async (): Promise<BasicUserListEntry[]> => {
    const { data } = await authorizedBackendGet<{ users: BasicUserListEntry[] }>(`/impersonation/users`);
    return data.users;
};

function useImpersonation(): UserImpersonationContextType & { userList: BasicUserListEntry[] | undefined } {
    const { userImpersonation } = useFlags();
    const impersonationContext = useContext(UserImpersonationContext);

    const userList = useQuery<BasicUserListEntry[], Error>({
        queryKey: QUERY_KEY,
        queryFn: fetchUserList,
        enabled: userImpersonation,
    });

    if (!userImpersonation) {
        return { userList: undefined, ...impersonationContext } as any;
    }

    return {
        userList: userList.data,
        ...impersonationContext,
    } as any
}

type ImpersionationState = {
    user: BasicUserListEntry | null;
    hash: string | null;
    error: string | null;
}

function reload() {
    setTimeout(() => {
        window.location.reload();
    }, 100);
}

export const UserImpersonation: React.FC<PropsWithChildren> = ({ children }) => {
    const queryClient = useQueryClient();
    const { userImpersonation } = useFlags();

    const [state, setState] = useState<ImpersionationState>(JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}"));

    useEffect(() => {
        if (state.hash) {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
            authProvider.signin();
        } else {
            localStorage.removeItem(STORAGE_KEY);
            authProvider.signin();
        }
    }, [state]);

    // If we change the impersonation state, we need to invalidate all queries
    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === STORAGE_KEY) {
                // Invalidate All Queries
                queryClient.invalidateQueries();
            }
        }

        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        }
    }, [queryClient]);

    return (
        <UserImpersonationProvider value={{
            startImpersonating: (user: BasicUserListEntry) => {
                if (!userImpersonation) {
                    return;
                }
                // SET Impersonating Loading
                authorizedBackendPost<{ user_id: string }>(`/impersonate`, { user_id: user.user_id }).then((res) => {
                    if (res.data.success) {
                        setState({ user, hash: res.data.key, error: null });
                        reload();
                    } else {
                        setState({ user: null, hash: null, error: res.data.error });
                        console.error("Failed to impersonate user", res.data.error);
                        reload();

                    }
                }).catch((err) => {
                    setState({ user: null, hash: null, error: err.message });
                    console.error("Failed to impersonate user", err);
                    reload();

                })
            },
            stopImpersonating: () => {
                setState({ user: null, hash: null, error: null });
                reload();
            },
            impersonationHash: state.hash,
            impersonatedUser: state.user,
        }}>
            {children}
        </UserImpersonationProvider>
    );
}

export default useImpersonation;