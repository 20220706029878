import { useMemo } from "react";
import { AverageCalculationProcessed, calculateStudentAverage, getInstructorsById, getStudentCountByInstructor, swapStudentInstructorMapping } from "./utils";
import HeaderRow from "./HeaderRow";
import AttendanceRow from "./AttendanceRow";
import InstructorSection from "./InstructorSection";
import { Day } from "@/lib/core/day";
// import { useInstructionalLog } from "@/hooks/useInstructionalLog";
import { SchoolAssociationOutput } from "@/hooks/useSchoolAssociations";
import { MaxCurriculumOutput } from "@/hooks/useMaxCurriculum";
import classNames from "classnames";

type Props = {
    latestCurriculumByStudent: MaxCurriculumOutput,
    startDate: Day;
    endDate: Day;
    // Instructor/Student pairs
    log: any;
    setLog: any; // Probably not needed
    holidays: Day[];
    students: SchoolAssociationOutput | undefined;
    upsert: any;
    writeable: boolean;
}

const HOLIDAY_CLASSES = "bg-gray-100";
const TODAY_CLASSES = "border border-2 border-black";

const LogTable: React.FC<Props> = ({ latestCurriculumByStudent, upsert, startDate, endDate, holidays, students, log, writeable }) => {
    const today = Day.today();
    const today_str = today.toString();
    const dates: Day[] = useMemo(() => {
        let dates: Day[] = [];
        startDate.callOverRange(endDate, date => {
            if (date.isWeekend()) {
                return;
            }
            dates.push(date.clone());
        });
        return dates;
    }, [startDate, endDate]);
    const holidaysSet = new Set(holidays.map(date => date.toString()));
    const studentInstructorMap = useMemo(() => swapStudentInstructorMapping(students?.students), [students]);
    const studentCountByInstructor = useMemo(() => getStudentCountByInstructor(students?.students), [students]);
    const instructorsById = useMemo(() => getInstructorsById(students?.students), [students]);

    const averageCalculation: AverageCalculationProcessed | undefined = useMemo(() => {
        if (!log || !studentCountByInstructor || !studentInstructorMap) {
            return undefined;
        }
        return calculateStudentAverage(startDate, endDate, studentCountByInstructor, studentInstructorMap, log);
    }, [log, startDate, endDate, studentCountByInstructor, studentInstructorMap]);

    if (!log || !averageCalculation) {
        return <div>Loading...</div>
    }

    return (
        <table className={classNames("table-fixed", "border-collapse", "w-full")}>
            <colgroup >
                <col />
                <col />
                <col />
                <col />
                <col />
                {dates.map(date => {
                    const ds = date.toString();
                    if (ds === today_str) {
                        let classes = `${TODAY_CLASSES}`;
                        if (holidaysSet.has(ds)) {
                            classes += ` ${HOLIDAY_CLASSES}`;
                        }
                        return <col key={ds} className={classes} />
                    } else
                        if (holidaysSet.has(ds)) {
                            return <col key={ds} className={HOLIDAY_CLASSES} />
                        } else {
                            return <col key={ds} />
                        }
                })}
            </colgroup>
            <thead>
                <HeaderRow dates={dates} />
            </thead>
            <tbody>
                <AttendanceRow today={today_str} rowTitle="School Avg" dates={dates} holidays={holidaysSet} log={averageCalculation.school} />
                {[...((students?.students.entries() ?? []))].map(([instructor, studentList], idx) => (
                    <InstructorSection
                        writable={writeable}
                        key={instructor}
                        rowNumber={idx}
                        average={averageCalculation[instructor]}
                        curriculumByStudentMap={latestCurriculumByStudent}
                        instructor={instructor}
                        instructorName={instructorsById.get(instructor)}
                        students={studentList}
                        dates={dates}
                        holidays={holidaysSet}
                        log={log}
                        today={today_str}
                        commitChanges={(log_id, _instructor, student_id, date, value, comment) => {
                            if (!writeable) {
                                return;
                            }
                            upsert?.mutate({
                                date,
                                value,
                                comment,
                                student_id,
                                instructional_log_entry_id: log_id,
                            });
                        }}
                        updateLog={(_instructor, _student_id, _date, _value, _comment) => {
                        }} />
                ))}
            </tbody>
        </table>
    )
}

export default LogTable;
