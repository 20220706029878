import { useCallback } from "react";
import Dialog, { openDialog } from "../Dialog";
import classNames from "classnames";

type Props = {
    day: string,
    student_id: string,
    log_id: string | undefined,
    comment: string,
    writable: boolean,
    onChange?: (id: string, log_id: string | undefined, comment: string | undefined) => void,
    onSubmit?: (id: string, log_id: string | undefined, comment: string | undefined) => void,
}

const Comment: React.FC<Props> = ({ day, student_id, log_id, comment, onChange, onSubmit, writable }) => {
    const commentDialogId = `log-comment-${student_id}-${day}`;
    const onCommit = useCallback((_event: any) => {
        onSubmit?.(day, log_id, comment);
    }, [comment, day, log_id, onChange]);

    const onInputChange = (ev: any) => {
        onChange?.(day, log_id, ev.target.value);
    };

    if (writable) {
        const hasComment = Boolean(comment);
        return (
            <>
                <Dialog id={commentDialogId} title="Add Note" showCloseButton={false}>
                    <form method="dialog" onSubmit={onCommit}>
                        <input className="py-1 px-3" type="text"
                            onChange={onInputChange}
                            value={comment}
                            placeholder="Note" maxLength={140} autoFocus />
                    </form>
                </Dialog >
                <button className={classNames("absolute top-0 right-0 focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0", { "text-ponce": hasComment }, { "text-gray-300": !hasComment })} onClick={_ => openDialog(commentDialogId)}>
                    <svg className="focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0" height="10" width="10"><polygon points="0,0 10,10 10,0" fill="currentColor" /></svg>
                </button>
            </>
        )
    } else if (comment) {
        return <>
            <Dialog id={commentDialogId} title="Note" showCloseButton={false}>
                <p>{comment}</p>
            </Dialog>
            <button className="absolute top-0 right-0 text-ponce" onClick={_ => openDialog(commentDialogId)}>
                <svg height="10" width="10"><polygon points="0,0 10,10 10,0" fill="currentColor" /></svg>
            </button>
        </>
    } else {
        return null;
    }
}

export default Comment;