import { ReactNode, useCallback, useEffect, useState } from "react"
import { LoginStep } from "./type"
import AskForEmailStep from "./AskForEmail"
import { Icons } from "../icons"
import { sendMagicLink } from "./util"
import { SearchParams } from "@/main"
import StartEnrollment from "./StartEnrollment"

const PleaseWait = () => {
    return <div className="flex flex-row gap-4 justify-center items-center"><Icons.spinner className="animate-spin" />Please wait...</div>
}

const SimpleMessage = (props: { message: string | ReactNode }) => {
    return <div className="text-center text-lg flex flex-row justify-center items-center">{props.message}</div>
}

export const ContactSupportMessage = (props: { message: string }) => {
    return <p className="text-xl">{props.message}. Please contact <a className="underline" href="https://www.tryonce.com/contact">support</a>.</p>
}

const LoginFlow = () => {
    const searchParams = new URLSearchParams(window.location.search);

    // TODO: Look at the search params to see if we need to respond to an associate with microsoft request
    const [currentStep, setCurrentStep] = useState(LoginStep.AskForEmail)
    const [associatedData, setAssociatedData] = useState<any>()

    useEffect(() => {
        const stytchUserId = searchParams.get(SearchParams.StytchUserId);
        const email = searchParams.get(SearchParams.Email);
        const attachToken = searchParams.get(SearchParams.AttachToken);
        const enrollmentJwt = searchParams.get(SearchParams.EnrollmentJwt);

        if (stytchUserId && attachToken) {
            const msUrl = `${import.meta.env.VITE_STYTCH_MICROSOFT_OAUTH_URL}&oauth_attach_token=${encodeURIComponent(attachToken)}`;
            window.location.assign(msUrl);
        }

        if (stytchUserId && !attachToken && currentStep != LoginStep.StartEnrollment) {
            setCurrentStep(LoginStep.StartEnrollment);
            setAssociatedData({ stytchUserId, email, enrollmentJwt });
        }
    }, [searchParams, currentStep]);

    const nextStep = useCallback((step: LoginStep, associatedData?: any) => {
        switch (currentStep) {
            case LoginStep.AskForEmail:
                switch (step) {
                    case LoginStep.ProcessFactor:
                        switch (associatedData.factor) {
                            case "Microsoft":
                                setCurrentStep(LoginStep.LoginWithMicrosoft)
                                break;
                            case "Google":
                                setCurrentStep(LoginStep.LoginWithGoogle)
                                break;
                            case null:
                            case undefined:
                                sendMagicLink(associatedData.email).then(() => {
                                    setCurrentStep(LoginStep.MagicLinkSent);
                                });
                                return <PleaseWait />
                        }
                        break;
                    case LoginStep.ContactSupport:
                        setCurrentStep(LoginStep.ContactSupport)
                        setAssociatedData(associatedData)
                        break;
                }

        }
    }, [currentStep]);

    switch (currentStep) {
        case LoginStep.AskForEmail:
            return <AskForEmailStep nextStep={nextStep} />
        case LoginStep.LoginWithGoogle:
            window.location.assign(import.meta.env.VITE_STYTCH_GOOGLE_OAUTH_URL);
            return <PleaseWait />
        case LoginStep.LoginWithMicrosoft:
            window.location.assign(import.meta.env.VITE_STYTCH_MICROSOFT_OAUTH_URL);
            return <PleaseWait />
        case LoginStep.StartEnrollment:
            return <StartEnrollment nextStep={nextStep} email={associatedData.email} userId={associatedData.userId} />
        case LoginStep.ProcessFactor:
            return <PleaseWait />
        case LoginStep.MagicLinkSent:
            return <SimpleMessage message="A magic link has been sent to your email address. Check your email and continue from there." />
        case LoginStep.ContactSupport:
            return <SimpleMessage message={associatedData as ReactNode} />
        default:
            return <SimpleMessage message={<ContactSupportMessage message="An unknown error occurred" />} />
    }
}

export default LoginFlow