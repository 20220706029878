import { Day } from "@/lib/core/day";
import AttendanceRow from "./AttendanceRow";
import LogRow from "./LogRow";
import { AverageDataMapProcessed } from "./utils";
import { SchoolAssociation } from "@/hooks/useSchoolAssociations";
import { InstructionalLogEntry } from "@/hooks/useInstructionalLog";

type Props = {
    rowNumber: number;
    instructor: string;
    instructorName: string | undefined;
    dates: Day[];
    log: Map<string, Map<string, InstructionalLogEntry>>;
    students: Map<string, SchoolAssociation>
    curriculumByStudentMap: Map<string, string>;
    average: AverageDataMapProcessed;
    holidays: Set<string>,
    updateLog: (log_id: string | undefined, instructor: string, student: string, date: string, value: string, comment?: string) => void;
    commitChanges: (log_id: string | undefined, instructor: string, student: string, date: string, value: string, comment?: string) => void;
    today: string;
    writable: boolean;
}

const InstructorSection: React.FC<Props> = ({
    rowNumber,
    instructor,
    students,
    dates,
    log,
    updateLog,
    commitChanges,
    curriculumByStudentMap,
    average,
    holidays,
    instructorName,
    today,
    writable
}) => {
    return (
        <>
            {
                [...(students.entries() ?? [])].map(([student_id, student], idx) => {
                    const logEntry = log?.get(student_id);
                    const curriculum = curriculumByStudentMap?.get(student_id);
                    return (
                        <LogRow editable={writable} key={`${instructor}-${student_id}`}
                            rowNumber={rowNumber * 1000 + idx}
                            instructor={student.instructor_name}
                            student={student.student_name}
                            student_id={student_id}
                            dates={dates}
                            holidays={holidays}
                            today={today}
                            maxCurriculum={curriculum}
                            log={logEntry}
                            updateLog={(date, log_id, value, comment) => { updateLog(log_id, instructor, student_id, date, value, comment); }}
                            commitChanges={(date, log_id, value, comment) => { commitChanges(log_id, instructor, student_id, date, value, comment); }} />
                    );
                })
            }
            <AttendanceRow today={today} rowTitle={`${instructorName} Avg`} dates={dates} log={average} holidays={holidays} />
        </>
    )
}

export default InstructorSection;