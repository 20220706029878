export enum LoginStep {
    AskForEmail,
    ProcessFactor,
    LoginWithGoogle,
    LoginWithMicrosoft,
    StartEnrollment,
    AssociateWithMicrosoft,
    FinishMSAssociation,
    MagicLinkSent,
    ContactSupport
}