import { FC, useEffect } from "react"
import { LoginStep } from "./type"
import { Button } from "../ui/button"
import { LocalStorageParams, SearchParams } from "@/main"
import { authorizedBackendPost } from "@/lib/backend"

type Props = {
    email: string,
    userId: string,
    nextStep: (step: LoginStep, associated_data?: any) => void
}

async function requestAttachment(provider: string) {
    const results = await authorizedBackendPost<{ provider: string }>(`/auth/stytch/attach`, {
        provider
    });
    return results.data;
}

async function startLogin(provider: string, nextStep: (step: LoginStep, associated_data?: any) => void) {
    const attachmentResponse = await requestAttachment(provider);
    if (!attachmentResponse) {
        nextStep(LoginStep.ContactSupport, { message: `Unable to complete association with ${provider}` })
        return;
    }
    const extraParams = `&oauth_attach_token=${encodeURIComponent(attachmentResponse.attach_token)}`;
    switch (provider) {
        case "Google":
            window.location.assign(`${import.meta.env.VITE_STYTCH_GOOGLE_OAUTH_URL}${extraParams}`);
            break;
        case "Microsoft":
            window.location.assign(`${import.meta.env.VITE_STYTCH_MICROSOFT_OAUTH_URL}${extraParams}`);
            break;
        default:
            nextStep(LoginStep.ContactSupport, { message: `Unable to complete association with ${provider}` })
            return;
    }
}

const StartEnrollment: FC<Props> = (props: Props) => {
    const searchParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        const tokenFromUrl = searchParams.get(SearchParams.EnrollmentJwt);
        if (tokenFromUrl) {
            localStorage.setItem(LocalStorageParams.JWT, tokenFromUrl);
        }
    }, [searchParams])

    return <div className="flex flex-col gap-4 pb-8">
        <p className="text-xl pt-4">Is <span className="text-ponce font-bold">{props.email}</span> a Google or Microsoft Account?</p>
        <Button variant="outline" onClick={() => {
            startLogin("Google", props.nextStep);
        }}>Continue with Google</Button>
        <Button variant="outline" onClick={() => {
            startLogin("Microsoft", props.nextStep);
        }}>Continue with Microsoft</Button>
    </div>
}

export default StartEnrollment